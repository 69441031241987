<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3">
          <h5 class="text-primary">
            {{ activityDetails.candidate.full_name }}
          </h5>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary font-weight-bold">
              <span>{{ activityDetails.expiry_date | formatDate }}</span>
              <span class="d-block"
                ><span class="font-weight-lighter pr-1">Days Left:</span
                >{{ activityDetails.expiry_date | remainingDays }}
              </span>
            </span>
          </div>
        </div>
        <div>
          <CRow class="d-flex p-2 justify-content-between">
            <span class="text-muted h6"
              >Comments : {{ activityDetails.comments || "--" }}</span
            >
            <div>
              <CButton
                class="ml-auto mr-0 mr-2 btn btn-primary"
                @click="navToCandidateDetails(activityDetails.candidate_uid)"
                >View Profile</CButton
              >
              <CButton
                class="px-2 f-12 btn btn-primary"
                @click="frontsheetModal = true"
              >
                Frontsheet
                <i class="fas fa-download ml-2"></i>
              </CButton>
            </div>
          </CRow>
          <div class="pt-2">
            <table class="w-100 table">
              <thead>
                <th>Type</th>
                <th>Name</th>
                <th>Download</th>
                <th>Preview</th>
              </thead>
              <tbody>
                <tr v-for="(document, index) in documents" :key="index">
                  <td>{{ document.lable }}</td>
                  <td>{{ document.name }}</td>
                  <td>
                    <CButton type="button" @click="fileDownload(document)">
                      <i class="fas fa-download"></i>
                    </CButton>
                  </td>
                  <td>
                    <CButton type="button" @click="preview(document)">
                      <i class="fas fa-eye"></i>
                    </CButton>
                  </td>
                </tr>
                <tr v-if="documents.length === 0">
                  <td colspan="4">
                    <h6 class="text-center text-muted">
                      No Documents to Preview
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pt-2">
            <ValidationObserver ref="reviewCandidate" v-slot="{ handleSubmit }">
              <form
                id="reviewCandidate"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <CRow>
                  <CCol lg="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12 col-md-12 required"
                        >Comment</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextareaInput
                            name="comments"
                            :value="review.comments"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <div class="d-flex justify-content-between p-4">
                  <CButton
                    type="button"
                    color="primary"
                    class="action-btn"
                    @click="onSubmit('Interview')"
                    >Interview</CButton
                  >
                  <CButton
                    type="button"
                    color="primary"
                    class="action-btn"
                    @click="onSubmit('Hold')"
                    >Hold</CButton
                  >
                  <CButton
                    type="button"
                    color="primary"
                    class="action-btn"
                    @click="onSubmit('Query')"
                    >Query</CButton
                  >
                  <CButton
                    type="button"
                    color="primary"
                    class="action-btn"
                    @click="onSubmit('Disapprove')"
                    >Disapprove</CButton
                  >
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <candidate-frontsheet
      v-if="frontsheetModal"
      :isShowPopup="frontsheetModal"
      :job_title="activityDetails.job.job_title"
      :candidate_uid="activityDetails.candidate_uid"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import pdf from "vue-pdf";
import PreviewModal from "@/components/reusable/PreviewModal";
import { BASE_URL } from "@/service_urls";
import CandidateFrontsheet from "@/containers/Frontsheet/CandidateFrontsheet.vue";
import { appendAccessToken } from '@/helpers/helper';
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    TextareaInput,
    PreviewModal,
    CandidateFrontsheet,
  },
  data() {
    return {
      review: {},
      payload: {},
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      frontsheetModal: false,
    };
  },
  computed: {
    ...mapGetters(["getUploadedDocument", "getCustomerId"]),
    documents() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument.map(
            ({
              document_type_id,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_uuid,
              comments,
            }) => {
              return {
                link: appendAccessToken(`/api/v1/customer/${this.getCustomerId}/file/${candidate_document_uuid}`),
                lable: document_type.comments,
                name: document_name,
                updatedDate: m(created_on).format("DD-MM-YYYY"),
                document_ext: document_ext.document_ext,
                document_id: candidate_document_uuid,
                comments: comments,
                document_type_id: document_type_id,
              };
            }
          )) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "fetchDocumentByCandidateID",
      "showToast",
      "fetchActivity",
      "downloadDocument",
    ]),
    handleInput(name, value) {
      Vue.set(this.review, name, value);
      Vue.set(this.payload, name, value);
    },
    navToCandidateDetails(candidate_uid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      //   this.$router.push({ path: `/candidate/${candidate_uid}` });
      let routeData = this.$router.resolve({
        path: `/candidate/${candidate_uid}`,
      });
      window.open(routeData.href, "_blank");
    },
    async onSubmit(action) {
      const isValid = await this.$refs.reviewCandidate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        job_id: this.activityDetails?.job_id,
        candidate_uid: this.activityDetails?.candidate_uid,
        comments: this.review?.comments,
        action_detail_id: this.activityDetails?.action_detail_id,
      };
      this.$emit("changeActivityStatus", { action, payload: finalPayload });
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
    },
    preview(data) {
      // var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      let _base_domain = window.location.origin;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(_base_domain + data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
  },
  mounted() {
    const {
      candidate_uid,
      candidate: { customer_uid, candidate_uuid },
      action_detail_id,
    } = this.activityDetails;
    this.fetchDocumentByCandidateID({ candidate_uid, candidate_uuid, customer_uid });
  },
  filters: {
    formatDate(data) {
      if (data)
        return m()
          .utc(data)
          .format("Do MMM YYYY");
      return "--";
    },
    remainingDays(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m();
        return alertDate.diff(todaysdate, "days");
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
</style>
