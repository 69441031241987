<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5>Candidate Frontsheet</h5>
        <button
          aria-label="Close"
          class="close"
          @click="$parent.frontsheetModal = false"
        >
          x
        </button>
      </template>
      <div id="source-html">
        <table :style="`${cssStyles.border} width: 100%`">
          <tr>
            <th colspan="2" :style="cssStyles.table_header" id="header1">
              Candidate Profile - {{ full_name }}
            </th>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith2RemHeight">Reference</td>
            <td :style="cssStyles.borderWithFontWeight">
              {{ typeInitial }}/{{ nameInitials }}/{{
                candidateProfile.candidate_uid
              }}
            </td>
          </tr>
          <tr v-if="job_title">
            <td :style="cssStyles.leftTdWith2RemHeight">Position</td>
            <td :style="cssStyles.borderWithFontWeight">
              <ul style="padding-inline-start: 20px">
                <li>
                  {{ job_title }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith2RemHeight">Qualification</td>
            <td :style="cssStyles.borderWithFontWeight">
              <ul style="padding-inline-start: 20px">
                <li v-for="(data, index) in sortedQualifications" :key="index">
                  {{ data.qualification_name }} ({{
                    data.country_name ? data.country_name : ""
                  }}
                  {{ data.year_awarded }})
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith2RemHeight">Key Notes</td>
            <td :style="cssStyles.borderWithFontWeight">
              <ul v-html="keyNotes" style="padding-inline-start: 20px"></ul>
            </td>
          </tr>
        </table>

        <table :style="`${cssStyles.border} width: 100%; margin-top: 10px`">
          <tr>
            <th colspan="2" :style="cssStyles.table_header" id="header2">
              Practicalities
            </th>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Current Experience</td>
            <td style="font-weight: 400">--</td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Availability</td>
            <td style="font-weight: 400">
              {{
                candidateProfile.available ? expectedJoinDate : availableFrom
              }}
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Registrations</td>
            <td style="font-weight: 400">
              {{ sliceLicense }}
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Gender</td>
            <td style="font-weight: 400">
              {{ gender }}
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Current Nationality</td>
            <td style="font-weight: 400">
              {{ nationality }}
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Birthplace</td>
            <td style="font-weight: 400">
              {{ placeOfBirth }}
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Status</td>
            <td style="font-weight: 400">
              {{ martialStatus
              }}{{
                candidateProfile.dependents
                  ? candidateProfile.dependents == 1
                    ? ` ${candidateProfile.dependents} dependent`
                    : ` ${candidateProfile.dependents} dependents`
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td :style="cssStyles.leftTdWith1RemHeight">Date of Birth / Age</td>
            <td style="font-weight: 400">{{ dob }} / {{ age }}</td>
          </tr>
        </table>

        <table :style="`${cssStyles.border} width: 100%; margin-top: 10px`">
          <tr>
            <th colspan="4" :style="cssStyles.table_header" id="header2">
              Remarks
            </th>
          </tr>
          <tr>
            <td
              :style="`${cssStyles.borderWithBgAndFontWeight} line-height: 2rem;`"
            >
              Rating of CV
            </td>
            <td :style="cssStyles.borderWithBgAndFontWeight">
              Excellent <input type="checkbox" disabled name="rating" />
            </td>
            <td :style="cssStyles.borderWithBgAndFontWeight">
              Good <input type="checkbox" disabled name="rating" />
            </td>
            <td :style="cssStyles.borderWithBgAndFontWeight">
              Average <input type="checkbox" disabled name="rating" />
            </td>
          </tr>
          <tr>
            <td
              :style="`${cssStyles.borderWithBgAndFontWeight} width: 30%; height: 4rem;`"
            >
              Remarks
            </td>
            <td colspan="3" :style="cssStyles.borderWithFontWeight"></td>
          </tr>
        </table>

        <table :style="`${cssStyles.border} width: 100%; margin-top: 10px`">
          <tr>
            <th colspan="3" :style="cssStyles.table_header" id="header4">
              Contact Details
            </th>
          </tr>
          <tr>
            <td :style="`width: 33%; ${cssStyles.borderWith2RemHeight}`">
              (Insert Name & Role)
            </td>
            <td :style="cssStyles.borderWith2RemHeight">T:</td>
            <td :style="cssStyles.borderWith2RemHeight">E:</td>
          </tr>
        </table>
      </div>
      <template #footer>
        <div>
          <CButton
            class="btn-secondary mr-2"
            @click="$parent.frontsheetModal = false"
          >
            Cancel
          </CButton>
          <CButton class="btn-primary" @click="exportToWord()">
            Download
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CandidateFrontsheet",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidate_uid: {
      type: Number,
      defauft: 0,
    },
    job_title: {
      type: String,
      defauft: "",
    },
  },
  data: () => ({
    candidateProfile: {},
    qualifications: [],
    licenses: [],
    languages: [],
    special_interests: [],
  }),
  computed: {
    ...mapGetters(["getLoggedAcronym"]),
    typeInitial() {
      let initial = "";
      switch (this.candidateProfile?.candidate_type_id) {
        case 1:
          initial = "D";
          break;
        case 2:
          initial = "N";
          break;
        default:
          break;
      }
      return initial || "--";
    },
    nameInitials() {
      return (
        this.candidateProfile?.first_name?.charAt(0).toUpperCase() +
        this.candidateProfile?.surname?.charAt(0).toUpperCase()
      );
    },
    full_name() {
      return this.candidateProfile?.full_name || "--";
    },
    gender() {
      return this.candidateProfile?.gender?.gender || "--";
    },
    dob() {
      return this.candidateProfile?.dob
        ? m(this.candidateProfile?.dob).format("DD-MMM-YYYY")
        : "--";
    },
    age() {
      return this.candidateProfile?.age || "--";
    },
    martialStatus() {
      return this.candidateProfile?.marital_status || "--";
    },
    nationality() {
      return this.candidateProfile?.nationality_names?.join(", ") || "--";
    },
    placeOfBirth() {
      return this.candidateProfile?.birth_place?.country_name || "--";
    },
    expectedJoinDate() {
      if (this.candidateProfile.expected_join_date) {
        let oneDay = 24 * 60 * 60 * 1000;
        let date = new Date(this.candidateProfile.expected_join_date);
        return `${Math.round(Math.abs(new Date() - date) / oneDay)} days`;
      }
      return "--";
    },
    availableFrom() {
      if (this.candidateProfile.available_from) {
        let oneDay = 24 * 60 * 60 * 1000;
        let date = new Date(this.candidateProfile.available_from);
        return `${Math.round(Math.abs(new Date() - date) / oneDay)} days`;
      }
      return "--";
    },
    sortedQualifications() {
      return (
        this.qualifications
          ?.sort((a, b) => {
            return b.qualification_name - a.qualification_name;
          })
          .sort((a, b) => {
            return b.year_awarded - a.year_awarded;
          }) || []
      );
    },
    initialQualYear() {
      let currentYear = new Date().getFullYear();
      return this.sortedQualifications?.length
        ? currentYear -
            this.sortedQualifications[this.sortedQualifications.length - 1]
              .year_awarded
        : 0;
    },
    sliceLicense() {
      if (this.licenses?.length) {
        return `${this.licenses[0].issue_authority_name} - ${this.licenses[0].country_name}`;
      }
      return "--";
    },
    keyNotes() {
      let returnData = "";
      if (this.sortedQualifications?.length) {
        let temp = this.sortedQualifications?.length
          ? new Date().getFullYear() -
            this.sortedQualifications[this.sortedQualifications.length - 1]
              .year_awarded
          : 0;
        if (temp > 0) {
          returnData = `<li>${temp} ${
            temp == 1 ? "year" : "years"
          } post initial qualification</li>`;
        }
      }
      if (this.special_interests?.length) {
        let temp = "";
        this.special_interests?.forEach((val) => {
          temp += `${temp ? ", " : ""}${
            val?.special_interest?.special_interest
              ? val?.special_interest?.special_interest
              : val?.other_special_interest
          }`;
        });
        returnData += temp ? `<li>Special Interest in ${temp}</li>` : "";
      }
      if (this.languages?.length) {
        let temp = "";
        this.languages?.forEach((val) => {
          temp += `${temp ? ", " : ""}${
            val?.language_proficiency?.proficiency
          } in ${val?.language?.language}`;
        });
        returnData += temp ? `<li>${temp}</li>` : "";
      }
      return returnData;
    },
    cssStyles() {
      let bgColor = "#dc3651";
      if (this.getLoggedAcronym == "uemae") bgColor = "#0094a8"; //Add org color whenever new org is added
      let border = `border: 1px solid ${bgColor};`;
      return {
        border,
        table_header: `background-color: ${bgColor};color: white;height: 2.5rem;text-align: center;`,
        leftTdWith2RemHeight: `${border} width: 30%;background: lightgrey;font-weight: 600;line-height: 2rem;`,
        leftTdWith1RemHeight: `border-right: 1px solid ${bgColor}; width: 30%;background: lightgrey;font-weight: 600; line-height: 1rem;`,
        borderWithFontWeight: `${border} font-weight: 400`,
        borderWithBgAndFontWeight: `${border} background: lightgrey; font-weight: 600;`,
        borderWith2RemHeight: `${border} font-weight: 600; line-height: 2rem;`,
      };
    },
  },
  methods: {
    ...mapActions(["getCandidateFrontSheet"]),
    exportToWord() {
      let header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
      let footer = "</body></html>";
      let sourceHTML =
        header + document.getElementById("source-html").innerHTML + footer;
      let blob = new Blob(["\ufeff", sourceHTML], {
        type: "application/msword",
      });
      let source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
      let filename =
        `${this.candidateProfile?.first_name}_${this.candidateProfile?.candidate_uid}_frontsheet` +
          ".doc" || "document.doc";
      let fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        fileDownload.href = source;
        // Setting the file name
        fileDownload.download = filename;
        //triggering the function
        fileDownload.click();
      }
      document.body.removeChild(fileDownload);
    },
  },
  mounted() {
    this.getCandidateFrontSheet(this.candidate_uid).then((res) => {
      const { data } = res;
      this.languages = data?.candidate_languages;
      this.candidateProfile = data;
      this.qualifications = data?.qualifications;
      this.licenses = data?.licenses;
      this.special_interests = data?.candidate_specialities;
    });
  },
};
</script>
